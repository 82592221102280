<template>
<div class="boxpa">
   <div class="flexcent mytitle">
      平台说明
    </div>
  <div class="detailbox">
   
    <div class="content">
      <p>
        建立面向人工智能基础技术及应用的检验检测基础服务平台，推进人工智能基础技术发展以及人工智能在工业制造、物流、交通、医疗和安防等重点领域的落地，发挥对人工智能产业发展的支撑保障作用。
      </p>
      <p>
        平台运营：中国电子标准化研究院
      </p>
      <p>
        版本：V 1.0
      </p>
      
     <!-- <html-frame v-if="newsdeta.contentUrl"  :url = "newsdeta.contentUrl"/>
     <html-frame v-else-if="newsdeta.fileUrl" :url = "newsdeta.fileUrl"/>  -->
     <!-- <div v-else> 连接地址缺失，请联系管理员 </div>  -->
    </div>
  </div>
 </div>
</template>

<script>
// import htmlFrame from "@/components/all/htmlFrame"; //引入尾部组件

export default {
  name: "about",  
  components: {
    // htmlFrame
  },

  props: {
    newsdeta: {
      typeof: [Object],
    },
  },
  created() {
    console.log(this.newsdeta);
  },
};
</script>


<style scoped lang="less">

.boxpa{
   height: 100%;
   .mytitle {
    padding: 2rem ;
    font-size: x-large;
    //box-shadow: 0 0 0.625rem #d1d1d1;
  }
    .content {
        p {
          // font-size: 16px;
          margin:0 20px 0 20px  auto;
          line-height:40px;
          text-indent: 2em;
        }
    }
    .title {
      margin-top: 1.25rem;
      text-align: center;
      font-weight: 550;
      font-size: 1rem;
    }
    .time {
      height: 2rem;
      font-size: 0.875rem;
      padding-bottom: 0.5rem;
      border-bottom: 0.0625rem solid #ececec;
    }
  }


@media screen and (min-width: 1000px) {
  // .title {
  //   height: 3.125rem;
  //   font-weight: 550;
  //   font-size: 1.5rem;
  // }
  // .time {
  //   height: 2rem;
  //   font-size: 0.875rem;
  //   padding-bottom: 0.5rem;
  //   border-bottom: 0.0625rem solid #ececec;
  // }
  // .content {
  //  border: 2px solid #f00;
  //   padding: 2.5rem 0.9375rem;
  //   /deep/ p {
  //     font-size: 0.875rem;
  //   }
  // }
}

@media screen and (max-width: 999px) {
  // .contentbox{
  //   padding: 0 .9375rem;
  //   box-sizing: border-box;
  //   width: 100%;
  //   .content {
  //       /deep/ p {
  //         font-size: 0.875rem;
  //       }
  //   }
  //   .title {
  //     margin-top: 1.25rem;
  //     text-align: center;
  //     font-weight: 550;
  //     font-size: 1rem;
  //   }
  //   .time {
  //     height: 2rem;
  //     font-size: 0.875rem;
  //     padding-bottom: 0.5rem;
  //     border-bottom: 0.0625rem solid #ececec;
  //   }
  // }
 

}
</style>
